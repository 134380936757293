import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { motion } from "framer-motion";
import { fadeInOut, staggerItems, item } from "../../utils/animations";
import {
  showIntroReducer,
  showSuccessReducer,
  showOptInReducer,
  currentStepReducer,
  completedGameReducer,
} from "../../store/gameSlice";

import Button from "components/Button/Button";
import Footer from "components/Footer/Footer";
import GamesShieldIcon from "./GamesShieldIcon";
import { importImages } from "utils/importImages";

const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

function Games({ currentLanguage }) {
  let navigate = useNavigate();
  const [restartGame, setRestartGame] = useState(false);

  const dispatch = useDispatch();
  const completedGame = useSelector((state) => state.game.completedGame);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(showSuccessReducer(false));
    dispatch(showIntroReducer(true));
    //dispatch(showOptInReducer(true));
    dispatch(currentStepReducer(0));

    if (
      completedGame[0].completed &&
      completedGame[1].completed &&
      completedGame[2].completed
    ) {
      //setRestartGame(true);
      //navigate("/success");
    }
  }, []);

  function gameStatus0() {
    if (completedGame[0].completed) {
      return "done";
    } else {
      return "active";
    }
  }

  function gameStatus1() {
    if (completedGame[1].completed) {
      return "done";
    } else {
      return "active";
    }
  }

  function gameStatus2() {
    if (completedGame[2].completed) {
      return "done";
    } else {
      return "active";
    }
  }

  const clickHandlerEnd = () => {
    navigate("/success");
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="games d-flex flex-column justify-content-center align-items-center">
      <motion.div
        className="container text-center d-md-flex align-items-center justify-content-center mb-6 mb-md-0"
        variants={staggerItems}
        initial="hidden"
        animate="show">
        <div className="row g-0 d-flex align-items-center justify-content-center mt-6 mt-md-0">
          <motion.div
            className={`game first col-12 mb-2 mb-md-0 ${gameStatus0()}`}
            variants={item}>
            {gameStatus0() === "done" ? (
              <>
                <img
                  className="complete d-none d-md-block"
                  src={images[`igra_1_${currentLanguage}.png`]}
                />
                <img
                  className="complete d-block d-md-none"
                  src={images[`igra_1_${currentLanguage}-mobile.png`]}
                />
              </>
            ) : (
              <>
                <img
                  className="active d-none d-md-block"
                  src={images["games-bg-game-1.svg"]}
                />
                <img
                  className="active d-block d-md-none"
                  src={images["games-bg-game-1-mobile.svg"]}
                />
                <div className="content ">
                  <Link to="/game/quiz-steps">
                    <div className="textbox d-flex flex-column p-3 h-100">
                      <div className="flex-grow-1 pt-5">
                        <h4>{t("S2G1H")}</h4>
                        <p className="text-white">{t("S2G1P")}</p>
                      </div>
                      <div>
                        <Button
                          label={t("Start")}
                          className="btn btn-sm py-2 btn-orange px-md-2 mt-3 w-100"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}
          </motion.div>

          <motion.div
            className={`game second col-12 mb-2 mb-md-0 ${gameStatus1()}`}
            variants={item}>
            {gameStatus1() === "done" ? (
              <>
                <img
                  className="complete d-none d-md-block"
                  src={images[`igra_2_${currentLanguage}.png`]}
                />
                <img
                  className="complete d-block d-md-none"
                  src={images[`igra_2_${currentLanguage}-mobile.png`]}
                />
              </>
            ) : (
              <>
                <img
                  className="active d-none d-md-block"
                  src={images["games-bg-game-2.svg"]}
                />
                <img
                  className="active d-block d-md-none"
                  src={images["games-bg-game-2-mobile.svg"]}
                />
                <div className="content ">
                  <Link to="/game/memory-game">
                    <div className="textbox d-flex flex-column p-3 h-100">
                      <div className="flex-grow-1 pt-5">
                        <h4 className="">{t("S2G2H")}</h4>
                        <p className="text-white">{t("S2G2P")}</p>
                      </div>
                      <div>
                        <Button
                          label={t("Start")}
                          className="btn btn-sm py-2 btn-orange px-md-2 mt-3 w-100"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}
          </motion.div>

          <motion.div
            className={`game third col-12 mb-2 mb-md-0 ${gameStatus2()}`}
            variants={item}>
            {gameStatus2() === "done" ? (
              <>
                <img
                  className="complete d-none d-md-block"
                  src={images[`igra_3_${currentLanguage}.png`]}
                />
                <img
                  className="complete d-block d-md-none"
                  src={images[`igra_3_${currentLanguage}-mobile.png`]}
                />
              </>
            ) : (
              <>
                <img
                  className="active d-none d-md-block"
                  src={images["games-bg-game-3.svg"]}
                />
                <img
                  className="active d-block d-md-none"
                  src={images["games-bg-game-3-mobile.svg"]}
                />
                <div className="content ">
                  <Link to="/game/puzzle-game">
                    <div className="textbox d-flex flex-column p-3 h-100">
                      <div className="flex-grow-1 pt-5">
                        <h4 className="">{t("S2G3H")}</h4>
                        <p className="text-white">{t("S2G3P")}</p>
                      </div>
                      <div>
                        <Button
                          label={t("Start")}
                          className="btn btn-sm py-2 btn-orange px-md-2 mt-3 w-100"
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </>
            )}
          </motion.div>
        </div>

        {completedGame[0].completed &&
          completedGame[1].completed &&
          completedGame[2].completed && (
            <motion.div className="end-game" variants={item}>
              <Button
                label={t("EndGame")}
                onClick={() => clickHandlerEnd()}
                className="btn btn-orange mb-2 "
              />
            </motion.div>
          )}
      </motion.div>
      <Footer />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60px", left: "20%" }}
        src={images["elm-marjetica.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "20%", right: "10%" }}
        src={images["elm-zvezda.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ top: "60%", right: "5%" }}
        src={images["elm-marjetica.png"]}
      />

      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "10%", left: "5%" }}
        src={images["elm-skoljka1.png"]}
      />
      <img
        className="position-absolute d-none d-xl-block"
        style={{ bottom: "0%", left: "50%" }}
        src={images["elm-skoljka2.png"]}
      />
    </motion.div>
  );
}

export default Games;
