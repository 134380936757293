import { useEffect } from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import { importImages } from "utils/importImages";

import Button from "components/Button/Button";
import Footer from "components/Footer/Footer";

import { fadeInOut, staggerItemsFade, itemFade } from "utils/animations";

const images = importImages(
  require.context("assets/images/_jub", false, /\.(png|jpe?g|svg)$/)
);

const HeroImage = () => (
  <img className="w-100 px-4 px-md-0" src={images["hero-visual.png"]} />
);

const HeroDescription = ({ t, gameData }) => (
  <div className="px-2">
    <h1 dangerouslySetInnerHTML={{ __html: t("headline") }} />
    <p className="lead text-white">{t("description")}</p>
  </div>
);

const HeroUsps = ({ t }) => (
  <div className="row mt-4">
    <div className="col-4 text-center">
      <img className="mb-2" src={images["hero-usp01.svg"]} />
      <p>{t("usp1")}</p>
    </div>
    <div className="col-4 text-center">
      <img className="mb-2" src={images["hero-usp02.svg"]} />
      <p>{t("usp2")}</p>
    </div>
    <div className="col-4 text-center">
      <img className="mb-2" src={images["hero-usp03.svg"]} />
      <p>{t("usp3")}</p>
    </div>
  </div>
);

const HeroButton = ({ clickSoundhandler, t }) => (
  <div variants={itemFade}>
    <Link to="/games" onClick={clickSoundhandler}>
      <Button label={t("Start")} className="btn btn-orange px-5 mt-3" />
    </Link>
  </div>
);

const Home = ({ gameData, play, stop, currentLanguage }) => {
  const { t } = useTranslation();

  const clickSoundhandler = () => {
    stop();
    play();
  };

  return (
    <motion.div
      variants={fadeInOut}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="home pt-6">
      <motion.div
        className="d-flex flex-column h-100"
        variants={staggerItemsFade}
        initial="hidden"
        animate="show">
        <div className="hero pt-2 pt-md-0 flex-grow-1">
          <div className="container text-center text-md-start">
            <div className="row d-flex align-items-center">
              <motion.div
                variants={itemFade}
                className="col-md-6 order-2 order-md-1">
                <HeroImage />
                <div className="d-block d-md-none">
                  <HeroUsps t={t} />
                  <HeroButton t={t} clickSoundhandler={clickSoundhandler} />
                </div>
              </motion.div>
              <motion.div
                variants={itemFade}
                className="col-md-6 order-1 order-md-2">
                <HeroDescription t={t} gameData={gameData} />
                <div className="d-none d-md-block">
                  <HeroUsps t={t} />
                  <HeroButton t={t} clickSoundhandler={clickSoundhandler} />
                </div>
              </motion.div>
            </div>
          </div>
        </div>

        <div className="prizes position-relative mt-5 pt-5 pt-md-6 pb-4 ">
          <img
            className="position-absolute d-none d-md-block"
            style={{ right: "10%", top: "-30px" }}
            src={images["elm-marjetica.svg"]}
          />
          <motion.div
            variants={itemFade}
            className="container d-flex justify-content-center">
            <div className="row d-flex  align-items-center">
              <div className="col-12 col-md-auto text-center text-md-left mb-5 mb-sm-0">
                <h2 className="text-orange mb-0 me-md-5">Nagrade</h2>
              </div>

              {currentLanguage === "rs" && (
                <>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes1.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">40x</h3>
                      <p className="text-orange">{t("prize1")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes4.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">40x</h3>
                      <p className="text-orange">{t("prize2")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes2.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">40x</h3>
                      <p className="text-orange">{t("prize3")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes3.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">40x</h3>
                      <p className="text-orange">{t("prize4")}</p>
                    </div>
                  </div>
                </>
              )}
              {currentLanguage === "sl" && (
                <>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes1.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">15x</h3>
                      <p className="text-orange">{t("prize1")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes2.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">15x</h3>
                      <p className="text-orange">{t("prize2")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes3.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">15x</h3>
                      <p className="text-orange">{t("prize3")}</p>
                    </div>
                  </div>
                </>
              )}
              {currentLanguage === "ba" && (
                <>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes1.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">50x</h3>
                      <p className="text-orange">{t("prize1")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes4.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">50x</h3>
                      <p className="text-orange">{t("prize2")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes2.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">50x</h3>
                      <p className="text-orange">{t("prize3")}</p>
                    </div>
                  </div>
                  <div className="col-12 col-md-auto d-flex align-items-center justify-content-center">
                    <img className="mb-2 me-1" src={images["prizes3.png"]} />
                    <div>
                      <h3 className="text-orange mb-0">50x</h3>
                      <p className="text-orange">{t("prize4")}</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </motion.div>
        </div>
        <Footer />
      </motion.div>
    </motion.div>
  );
};

export default Home;
