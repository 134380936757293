import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";

import { data } from "../../data/data";

// Import views and components

import MemoryGame from "./Games/MemoryGame/MemoryGame";
import PuzzleGame from "./Games/PuzzleGame/PuzzleGame";
import QuizSteps from "./Games/QuizSteps/QuizSteps";

import Intro from "./Intro/Intro";
import OptIn from "../OptIn/OptIn";
import Success from "./Success/Success";
import Fail from "./Fail/Fail";

import { AnimatePresence } from "framer-motion";

// Import media

function Game() {
  const showIntro = useSelector((state) => state.game.showIntro);
  const showSuccess = useSelector((state) => state.game.showSuccess);
  const showFail = useSelector((state) => state.game.showFail);
  const showOptIn = useSelector((state) => state.game.showOptIn);
  const optIn = useSelector((state) => state.game.optIn);

  const { game } = useParams();

  const gameId = game.replace(/-/g, "");

  return (
    <AnimatePresence>
      {showOptIn === true && optIn === false && showSuccess === false ? (
        <OptIn step="before" gameData={data[gameId]} />
      ) : game === "puzzle-game" ? (
        <PuzzleGame path={game} id={game} gameData={data[gameId]} />
      ) : game === "memory-game" ? (
        <MemoryGame path={game} id={game} gameData={data[gameId]} />
      ) : game === "quiz-steps" ? (
        <QuizSteps path={game} id={game} gameData={data[gameId]} />
      ) : null}
    </AnimatePresence>
  );
}

export default Game;
